/**
 * Arlo SFCC Static
 * New bundles page entry point.
 */


import Modal from './components/Modal';

 /**
 * Define the scroll event handler function
 */
function scrollHandler() {
    var scrollPosition = window.scrollY;
    var scrollCoordinates = window.promoOverlayPosition;
    if (scrollPosition >= scrollCoordinates) {
        // Trigger the email signup pop-up here
        new Modal('promo-overlay-content');  // eslint-disable-line no-new
        // Remove the scroll event listener after the modal is triggered
        window.removeEventListener('scroll', scrollHandler);
    }
}

// Attach the scroll event listener
window.addEventListener('scroll', scrollHandler);

